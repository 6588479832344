


































import { Vue, Component } from 'vue-property-decorator'
import InputCommon from '@/components/common/InputCommon.vue'
import { userAPI } from '@/api/user.api'
import { Message } from 'element-ui'

@Component({
  components: { InputCommon },
})
export default class Login extends Vue {
  form = {
    login: '',
    email: '',
  }

  async handleRecoveryPassword() {
    const [error, data] = await userAPI.recoveryPassword(this.form)

    if (!error && data) {
      await this.$router.push({ name: 'Login' })

      Message.error({
        message: 'Вам на почту отправлено письмо',
      })
    }
  }
}
