import { render, staticRenderFns } from "./RecoveryPassword.vue?vue&type=template&id=58ebc2e7&scoped=true&"
import script from "./RecoveryPassword.vue?vue&type=script&lang=ts&"
export * from "./RecoveryPassword.vue?vue&type=script&lang=ts&"
import style0 from "./RecoveryPassword.vue?vue&type=style&index=0&id=58ebc2e7&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "58ebc2e7",
  null
  
)

export default component.exports