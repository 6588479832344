















import { Vue, Component } from 'vue-property-decorator'
import RecoveryPasswordForm from '@/components/forms/RecoveryPasswordForm.vue'

@Component({
  components: { RecoveryPasswordForm },
})
export default class recoveryPassword extends Vue {
  get isAuth() {
    return this.$store.getters['user/authorized']
  }

  async auth(data: object): Promise<void> {
    await this.$store.dispatch('user/login', data)

    if (this.isAuth) {
      this.$router.push({ name: 'Statistic' })
    }
  }
}
